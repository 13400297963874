.app-container {
    .checker-container .checker-tab-container {
        padding-left: 0;
        padding-right: 0;
    }

    .checker-container {
        padding-left: 0;
        padding-right: 0;
        background-color: #fff;
        ul {
            margin-bottom: 0;
        }
    }

    .checker-top-tab-container {
        padding-left: unset;
        padding-right: unset;

        .checker-panel {
            max-width: 100%;
            height: 6rem;
            .checker-filter-row {
                flex-wrap: nowrap;
                .dataSelection {
                    display: flex;
                    flex-direction: row-reverse;
                    padding-right: 2.3rem;
                }
            }
            #acSubTypeCheckerDetail{
                div[class^="ds-combobox"]{
                    flex-wrap: inherit;
                 }
             }
        }
    }
    .checker-fleet {
        position: absolute;
        z-index: -4;
        background-color: #F8F8FF;
        padding: 1.5rem;
        div[class^="fleet-card-content"] {
            padding-bottom: 0;
        }
        .data-warning{
            text-align: center;
            color: #F23346;
         }
    }
}