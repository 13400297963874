@import '../../../variables.scss';

@mixin coloum-filter-row {
    margin-bottom: 0.4rem;
    margin-left: 0.2rem;
 }

 div[id="column-filter-id"] {
    position: absolute;
    z-index: -1;
}

.app-container {
    .filterSelection {
        .data-filter-button {
            border-radius: 0.3rem;
            background-color: #002D80;
            color: $font_white;
            margin-top: 0.3rem;
            margin-right: -1.37rem;

            &:hover &:focus &:focus-visible {
                margin-top: 2.5rem;
                background-color: #002D80;
                color: $font_white;
            }
            .filter-count {
                left : 1rem;
                bottom: 1rem;
            }
            div[class^="ds-badge"] {
                background-color: #da1884;
            }
        }
    }
}

.data-filter-popup-container {
    .filter-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -16%);
        width:50rem;
        background-color: $font_white;
        display: flex;
        border-radius: 0.3rem;
        border: 1px solid #34495E;
    }
    .parent-model-class {
        margin-bottom: 0.2rem;
    }
    .filterSort-header-label {
        padding-bottom: 0.5rem;
        font-weight: bold;
        background-color: #E8EDF3;
        padding-top: 0.5rem;
    }
    .filter-icon-color {
        color: #808080;
        margin-top: 0.4rem;
    }
    .filterby-header-label {
        padding-bottom: 0.1rem;
        padding-top: 0.4rem;
        font-weight: bold;
        padding-left: 1.1rem;
    }
    .nodata-label {
        padding-bottom: 0.1rem;
        padding-top: 0.4rem;
        color: rgb(255,0,0);
        padding-left: 1.1rem;
    }
    .data-filter-row {
        @include coloum-filter-row;
    }
    .data-filter-label {
        color: #505d74;
        padding-bottom: 0.2rem;
        padding-top: 0.5rem;
        margin-right: -4rem;
    }
    .sort-by {
        margin-left: 0.3rem;
        margin-top: 0.5rem;
    }
    .dynamic-header-outside {
        padding-bottom: 0.2rem;
    }
    .modal-close-icon {
        margin-left: 13rem;
        color: #040506;
        cursor: pointer;
        margin-top: 0.6rem;
    }
    .dynamic-header {
        margin-top: 0.2rem;
        font-weight: bold;
    }
    .filer-sort-header-title {
        margin-left: 1rem;
    }
    .filter-column-color {
        color: #002D80;
    }
    .tilte-header-filterby {
        margin-top: 0.6rem;
        margin-bottom: 0.6rem;
        .spinner-align {
            margin-left: 1rem;
        }
    }
    .tilte-header-sortby {
        @include coloum-filter-row;
        margin-top: 1.5rem;
    }
    .clear-apply-button {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }
    .apply-button {
        background-color: #063B9E;
        border-color: #063B9E;
        &:hover &:focus &:focus-visible {
            background-color: #002D80;
            accent-color: #002D80;
            color: #FFFFFF;
        }
    }
}

body .hybrid-column-level {
    width: 33rem;
    .checkbox-action-buttons{
     display: flex;
     justify-content: flex-end;
     margin-right: 0.25rem;
    }
    .clear-all{
        display: flex;
        padding-right: 4rem;
        flex-direction: row-reverse;
    }
}