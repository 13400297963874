
.toolTipStyle{
    padding: 0.3em;
    overflow-wrap: break-word;
    white-space: initial;
    background-color: #283747;
    color: #fff;
    border-radius: 0.3rem;
    opacity: 0.9;
    max-width: 50em;
}
.popoverContainer{
    cursor: pointer;
    width: 100%;
    .customPopoverTooltip{
        transition: opacity 1.5s ease;
        font-size: small;
    }
}