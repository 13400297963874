.elipseColumn{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.no-details {
    color: #FD0303;
}
.no-concurrent-sb {
    color: #283747;
}
.no-detail-link {
    pointer-events: none;
}
.innerCheckerTable{
    table-layout: fixed;
}
.unmatch-font{
    font-weight: normal;
}
.match-font{
    font-weight: 900 !important;
}
#reason-detail-popup {
    .redirect-airnavx {
        padding: 0;
        font-size: 0.875rem;
        border: 0;
        text-decoration: underline;
        color: #063b9e;
        width: 100%;
        :hover {
            cursor: pointer;
        }
    }
    .concurr-msn-sb {
        margin: 0;
        width: inherit;
        .elipseColumn .cell-values{
            cursor: pointer;
        }
    }
    .popup-fin-chip {
        display: flex;
    }
}
