.app-container {
    .browser-container .browser-tab {
        display: flex;
    }

    .table-container {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        position: absolute;
        z-index: -4;
    }

    .table-disable {
        opacity: 0.25;
        pointer-events: none;
        position: absolute;
        z-index: -4;
    }

    .browser-body-container,
    .filter-panel-container,
    .browser-container {
        padding-left: 0;
        padding-right: 0;
    }

    .browser-container {
        background-color: #fff;

        ul {
            margin-bottom: 0;
        }
    }

    .spinner-container {
       position: absolute;
       margin-top: 9.375rem;
       left: 50%
     }
     .no-data-text{
        color:#d0312d;
        font-weight: bold;
        margin-top: 4rem;
        display: flex;
        justify-content: center;
    }

    .no-records-text {
        margin-top: 4rem;
    }

    .user-api-loading {
        position: absolute;
        left: 50%;
        top: 50%;
    }
}