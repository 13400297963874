div[id="reason-detail-popup"] {
    position: absolute;
    z-index: -1;
    .browserTable{
        padding: 0;
    }
    #reasonContainer .checker-data-table{
        height: calc(100vh - 230px);
    }
}

.reason-detail-modal {  
    div[class^="ds-backdrop"] {
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        position: fixed;
        background-color: rgba(80, 81, 83, 0.6);
        -webkit-tap-highlight-color: transparent;
    }
    .reason-help-icon {
        color:#063B9E;
        cursor: pointer;
    }
    .modal-container{
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -20%);
        padding: 0.1rem;
        width: calc(100vw - 1rem);
        height: calc(158vh - 37%);
        background-color: #ffffff;
        display: flex;
        border: 1px solid #ffffff;
        .reason-detail-header{
            background-color: #f0f4f6;
            justify-content: space-between;
            font-size: medium;
            font-weight: bold;
            padding-left: 1.5rem;
            margin: -0.131rem;
        }
        .reason-fin-text{
            display: inline-flex;
        }
        .reason-fin-status{
            margin-left: 0.125rem;
            display: inline-flex;
        }
        .detail-popup-container{
            padding: unset;
            .reason-tabs{
                padding: 0.313rem 0 0 1.438rem;
            }
            .selected-reason-header{
                padding: 0.463rem 0 0 2.7rem;
                width: calc(100% - 30%);
                font-size: small;
                .reason-header-value{
                    margin-right: 1%;
                    .hyperlink-value {
                        padding: 0;
                        font-size: small;
                        color: #063b9e;
                        text-decoration: underline;
                    }
                    .popup-fin-chip{
                        display: flex;
                        .popup-fin-val .chip-container {
                          flex: 1;
                        }
                      } 
                }
                .reason-header-text{
                    font-weight: bold;
                }
                div[class^="ds-col"]{
                    flex: 1 0 auto;
                }
            }
            .reason-popup-container div[role=rowgroup] div[role=row] div[role=cell] {
                padding-left: 10px;
                padding-top: 5px;
                padding-bottom: 5px;
                width: fit-content;
                &:nth-child(3), &:nth-child(4){
                    padding: unset;
                }
            }
            .reason-popup-container{
                z-index:0;
            }
            #reasonContainer.checker-details-container{
                z-index:0;
                position: absolute;
                background-color: #fff;
            }
            .chip-container {
                position: relative;
                padding-left: 0.5rem;
              }
            .custom-chips {
                height: 1rem;
                color: #ffffff;
                span {
                  padding: 0 0.55rem 0 0.55rem;
                  font-size: 0.675rem;
                }
              }
              .tba {
                background-color: #2a78fb;
              }
              .mismatch {
                background-color: #ff0000d6;
              }
              .match {
                background-color: #008000;
              }
        }
    }
}