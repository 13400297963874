div[id="change-output-modal-popup"] {
    position: absolute;
    z-index: -1;
}
.app-container {
    .changeOutput {
        padding-bottom: 1.2rem;
        .change-output-button {
            border-radius: 0.3rem;
            background-color: #002D80;
            color: #FFFFFF;
            margin-top: 0.3rem;
            margin-right: -1.37rem;
            &:hover &:focus &:focus-visible {
                margin-top: 2.5rem;
                background-color: #002D80;
                color: #FFFFFF;
            }
        }
    }
}


.change-output-model {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -20%);
    padding: 1rem 1rem 0.3rem 1rem;
    width: 50rem;
    background-color: #FFFFFF;
    display: flex;
    border-radius: 0.3rem;
    border: 0.06rem solid #011133;
    .change-output-header-label {
        padding-bottom: 0.6rem;
        font-weight: bold;
    }
    .change-output-container {
        margin: 1rem;
    }
    .help-icon {
        color:#063B9E;
        cursor: pointer;
    }
    .data-filter-label {
        color: #505d74;
        padding-bottom: 0.2rem;
        padding-top: 0.5rem;
    }
    .dynamic-header-inside input[type='checkbox']:checked+label::before {
        border-color: #da1884;
        background-color: #FFFFFF;
    }
    .dynamic-header-inside input[type='checkbox']:checked+label::after {
        background-color: #da1884;
    }
    .dynamic-header-outside {
        padding-bottom: 0rem;
    }
    .change-output-close {
        cursor: pointer;
        margin-left: 37rem;
    }
    .cancel-apply-buttons {
        .apply-button {
            color: #FFFFFF;
            background-color: #063B9E;
            border-color: #063B9E;
            border-radius: 0.18rem;
            &:hover &:focus &:focus-visible {
                background-color: #002D80;
                accent-color: #002D80;
                color: #FFFFFF;
            }
        }
    }
}
