.AppBody .acm-container{
    background-color: #D8D8D8;
    padding: 0 4rem 0.25rem 4rem;
    position: absolute;
    z-index: -1;
.acm-body-container{
    background-color: #FFFF;
    height: -webkit-fill-available;
}
.msn-col .hybrid-combo-button .filter-menu-button{
    width: 31vw;
}
    .acm-row-container{
        padding: 1rem 0;
    }
.acm-filter-text{
    font-weight: 500;
}
.acm-report-text{
    font-weight: 500;
    color: #002D80
}
.export-download-button-ACM{
    padding : 0.313rem 9.375rem;
    background-color: #063B9E;
    border-color: #063B9E;
    &:hover &:focus &:focus-visible {
        background-color: #002D80;
        accent-color: #002D80;
        color: #FFFFFF;
    }
}
.acm-col-container{
    padding-left : 3.75rem
}
.required-fields{
    color:#d0312d;
    padding-left: 0.2rem;
}
.acm-button-container{
    padding: 2rem 0 0 3.75rem ;
}
.acm-row-button-container{
    padding: 0 0 5.2rem 0;
}
.help-icon {
    color:#063B9E;
    cursor: pointer;
    margin-top: 0.43rem;
}
}
body .hybrid-acm-popover{
    width: 31vw;
    .checkbox-action-buttons{
        display: flex;
        justify-content: flex-end;
        margin-right: 0.25rem;
    }
    .clear-all{
        display: flex;
        justify-content: flex-end;
    }
    .select-all {
        width: auto;
    }
}