
$color-selected: #ff1493;
$color-background: #FFFFFF;
$padding-space: 3rem;
$padding-drawer: 0.6rem;
$font-weight-bold: bold;
$square-size: 1.4rem;
$font-size: 0.875rem;
$checkbok-background-color: #063B9E;
$selected-all-label-color: #282e3a;

@mixin selected-text {
  font-weight: $font-weight-bold;
  font-size: $font-size;
}

div[id="fin-edit-drawer"] {
  bottom: auto;
}

.custom-drawer-container {
  overflow: hidden;
  .bottom-drawer-header {
    background-color: $color-background;
    .bottom-drawer-left-padding {
      padding-left: $padding-space;
      padding-top: $padding-drawer;
      padding-bottom: $padding-drawer;
      .square {
        background-color: $color-selected;
        text-align: center;
        border-radius: 0.2rem;
        height: $square-size;
        width: 1.8rem;
        .selectedColor {
          color: $color-background;
          font-weight: 400;
          font-size: $font-size;
        }
      }
      .selectedText {
        @include selected-text;
        padding-top: 0.1rem;
      }
      .checkboxSelectAll {
        padding-left:0.2rem;
        padding-right: 1.1rem;
        padding-top: 0.3rem;
        .select-checkbox input[type='checkbox']:checked+label::before {
          background-color: $checkbok-background-color;
        }
        .select-checkbox input[type='checkbox']:checked+label {
          font-weight: $font-weight-bold;
          color: $selected-all-label-color;
        }
     }
    }
    .bottom-drawer-right-padding {
      padding-right: $padding-space;
      padding-top: 0.4rem;
      .editIconStyle {
        margin-left: 0.438rem;
        color: #002D80;
      }
      .editText {
        @include selected-text;
        color: $checkbok-background-color;
        cursor: pointer;
        padding-left: 0.5rem;
      }
    }
    .bottom-drawer-center-padding {
      padding-left: $padding-space;
      padding-top: $padding-drawer;
      padding-bottom: $padding-drawer;
      .rangeText {
        @include selected-text;
        padding-top: 0.1rem;
        color: #ffa500;
      }
    }
  }
}

