.cmiAppHeader {
    .btn-font-small {
        border: none ;
    }
    .btn-highlight {
        background-color: #8b8da180;
        border-radius: 0.25rem;
    }
    .hide-badge {
        [class^="ds-badge"] {
            visibility: hidden;
        }
    }
    .dwn-btn {
        top: 3px;
        div[class^="ds-badge"] {
            background-color: #da1884;
        }
    }
}
.AppBody .cmiAppHeader {
    div[class^="ds-header"] {
        div[class$="-logo"] {
            background-image: url(../../../../public/airbus_logo.png) !important;
            margin-bottom: 0.5rem !important;
            margin-right: 0.3rem !important;
        }
         h1[class$="-appname"] 
        {
            flex: 0 !important;
            padding-right: 0rem !important;
            margin-right: 0.2rem;
        }
    }
    .powered-by{
        font-size: 0.75rem;
        font-weight: 400;
        padding-top: 0.19rem !important;
        width: 10rem !important;
        .skywise-span{
            padding-right: 0.19rem !important;
            width: 4.6rem !important;
        }
        .skywise-brand{
            width: 4rem !important;
            height: 1.1rem !important;
            background-image: url(../../../../public/skywise_logo.png) !important;
            background-repeat: no-repeat;
        }
    }
    .select-dropdown{
        box-shadow: none;
        background-color: initial;
        &:hover, &:focus, &:focus-visible{
            background-color: inherit;
            border: none;
            box-shadow: none;
        }
    }

    .custom-header{
        margin-right: -1.625rem;
    }
} 