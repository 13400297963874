.download-drawer-container {
    .drawer-cards-design {
        position: relative;
        width: --webkit-fill-available;
        background-color: #ffffff;
        overflow-x: hidden;
        height: calc(100vh - 7.5rem);
        padding: 0;
    
        .download-drawer-card {
            padding: 0.8rem 1.75rem 0rem 1.75rem;
        }
    
        .download-drawer-card-highlighted {
            padding: 0.8rem 1.75rem 0rem 1.75rem;
            background-color: #6e84a717;
        }
    
        .download-failed-filename {
            font-weight: normal;
            color: #282e3a;
            margin-left: 0.625rem;
            width: 320px;
            word-break: break-all;
        }
    
        .download-filename {
            font-weight: normal;
            margin-left: 0.625rem;
            width: 320px;
            word-break: break-all;
        }
    
        .download-align {
            display: flex;
            justify-content: flex-end;
        }
    
        .download-status-fail {
            font-size: 0.813rem;
            font-weight: bold;
            color: #e34f27;
            border: 0.063rem solid transparent;
            border-radius: 1rem;
            width: 5.938rem;
            display: flex;
            justify-content: center;
            background-color: #f3baba85;
            height: 28px;
        }
    
        .download-status-inProgress {
            font-size: 0.813rem;
            font-weight: bold;
            width: 5.938rem;
            border: 0.063rem solid transparent;
            border-radius: 1rem;
            background-color: #fed0436e;
            display: flex;
            justify-content: center;
            height: 28px;
        }
    
        .download-status-success {
            font-size: 0.813rem;
            font-weight: bold;
            color: #011133;
            border: 0.063rem solid transparent;
            border-radius: 1rem;
            width: 5.938rem;
            display: flex;
            justify-content: center;
            background-color: rgb(155 217 51 / 40%);
            height: 28px;
        }
    
        .retry-button {
            border: none;
            font-weight: bold;
            padding-left: 0.625rem;
            font-size: 1.063rem;
        }
    
        .download-time-text {
            margin-left: 0.625rem;
            color: #8a8f92d6;
        }
    
        .divider-design {
            position: relative;
            right: 1.7rem;
            width: 31.4rem;
            border-bottom: 1px solid rgb(193 199 211 / 44%);
            height: 0.938rem;
            margin-right: -75px;
            margin-top: 0;
            margin-bottom: 0;
        }
    
        .no-data-text {
            color: #d0312d;
            font-weight: bold;
            margin-top: 4rem;
            display: flex;
            justify-content: center;
        }
    }
}