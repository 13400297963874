@mixin checker-fin-edit-history-header {
    font-weight: bold;
    color: #5D6D7E;
    padding-left: 0.5rem;
    border-bottom-color: lightgray;
    align-items: flex-start;
}

.fin-edit-modal-popup-container {
    .modal-container {
        .fin-edit-container {
            padding-left: 0rem;
            padding-right: 0rem;
        }
        .fin-edit-disable-container{
            opacity: 0.5;
            pointer-events: none;
            padding-left: 0rem;
            padding-right: 0rem;
        }
        position: absolute;
        top: 14%;
        left: 50%;
        transform: translate(-50%, -10%);
        padding: 0rem 0.1rem 0.1rem 0.1rem;
        width: 65rem;
        height: 33.2rem;
        background-color: white;
        display: flex;
        border-radius: 0.3rem;
        border: 1px solid rgba(1,17,51,0.6);
        margin-top: 0.5rem;        
    }
    .fin-edit-spinner{
        position: absolute;
        z-index: 4;
        width: 2rem;
        height: 2rem;
        opacity: 1;
        top: 50%;
        left: 45%;
    }
    .header-section {
        padding-left:1rem;
        padding-right:1rem;
        border-bottom-style: groove;
    } 
    .modal-container-outer {
        height: 24rem;
        overflow-y: scroll;
        .modal-container-card {
            max-width: 100%;
            margin: 0.5rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
    }
    .modal-container-outer::-webkit-scrollbar {
        width: 0.5rem;
        height: 1.875rem;
    } 
    .modal-container-outer::-webkit-scrollbar-thumb{
        height: 5rem;
    }
    .modal-content{
        overflow-y: auto;
        font-size: 0.875rem;
    }
    .inline-button{
        padding-top: 1rem;
        padding-right:1rem;
    }
    .reset-button{
        margin-right: 0.25rem;
        color:#063B9E;
        background-color: #FFFFFF;
        border-color: #063B9E;
    }
    .dataSelectionPrimary {
        .save-button{
            color:#FFFFFF;
            background-color: #063B9E;
            border-color: #063B9E;
            border-radius: 3px;
            &:hover &:focus &:focus-visible{
                background-color: #002D80;
                color: #FFFFFF;
                }
        }
    }
    .save-button-disabled{
        color:#FFFFFF;
        background-color: #A5C0F2;
        border-color: #A5C0F2;
        border-radius: 3px;
    }
    .header-label
    {
        padding-bottom: 0.2rem;
        font-weight: bold;
        background-color: #E8EDF3;
    }
    .header-title {
        margin-left: 1rem;
        padding-top: 1rem;
    }
    .header-label-description
    {
        font-size: 11px;
        margin-left: 1rem;
        margin-bottom: 0.5rem;
        color: #d0312d;
    }
    .modal-close-icon
    {
        margin-left: 29.25rem;
        margin-top: 1rem;
        color: #020a18;
        cursor: pointer;
    }
    .error-message{
        margin-right: 21.125rem;
        color: #d0312d;
    }
    .commends-section{
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .comments-header-label{
        padding: 0.2rem;
        color: #5D6D7E;
        font-weight: bold;
    }
    .status-section {
        padding-left:1rem;
    }
    .history-section{
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .required-comments-fields{
        color: #d0312d;
        margin-left: -0.2rem;
    }
    .text-box-error{
        box-shadow: 0 2px 0 0 #e4002b
    }
    .text-box-initial{
    box-shadow: 0 -1px 0 0 #a2acbd inset
    }

    .fin-status-container{
        .fin-status-header-label{
            font-weight: lighter;
            color: #5D6D7E;
        }
        .fin-status-header-value{
            font-weight: bold;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .select-status-header-label{
            color: #5D6D7E;
        }
        .changes-fin{
            padding-top: 1rem;            
            color: #5D6D7E;
            font-weight: bold;
        }
        .changes-fin-list{
            padding-top: 0.5rem;
        }
        .fin-status-radio {
            margin-right:2rem;
            font-weight: bold;
            color: #020a18;
        }
        .fin-status-radio input[type='radio']:checked+label {
            font-weight: bold;
        }
        .fin-margin{
            margin-left: -0.4rem !important;
        }
    }
    
    .checker-fin-edit-history {
        .fin-edit-history-title {
            button[class^="ds-collapsible"] {
                @include checker-fin-edit-history-header();
            }
            div[class^="ds-collapsible"] {
                height: 11.1rem;
                overflow-x: hidden !important;
                overflow-y: unset !important;
                padding: 0rem !important;
            }
            div[class^="ds-collapsible"]::-webkit-scrollbar {
                width: 0.2rem;
                height: 8px;
            } 
        }
        .fin-edit-history-title-load{
            button[class^="ds-collapsible"] {
                @include checker-fin-edit-history-header();
            }
            div[class^="ds-collapsible"] {
                height: auto !important;
                overflow: hidden !important;
                padding: 0rem !important;
            }
        }
        .history-content{
            padding-left: 1.5rem;
        }
        .history-content:nth-of-type(even){
            background-color: #f7f9fa;
        }
        .fin-history-header-value{
            font-weight: bold;
        }
        .fin-history-header-label{
            font-weight: lighter;
            color: #5D6D7E;
        }
        .fin-history-header-label-comment{
            color: #5D6D7E;
        }
        .fin-history-header-comment-value{
            font-weight: lighter;
            font-size: 0.9rem;
        }
        .status-change-arraw{
            margin-left: 0.2rem;
            margin-right: 0.2rem;
        }
        .status-change-span{
            font-weight: lighter;
        }	
        .checker-spinner{
            opacity: 1;
            left: 45%;
        }
        .divider-line {
            width: 108%;
            margin: 0rem;
            margin-left: -2rem !important;
        }
    }
}
