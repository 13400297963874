.disclaimer-popup-popup-container {
    .modal-container {
        .disclaimer-container {
            padding-left: 0rem;
            padding-right: 0rem;
        }
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -10%);
        padding: 0rem 0.1rem 0.1rem 0.1rem;
        background-color: white;
        border-radius: 0.3rem;
        border: 1px solid rgba(1,17,51,0.6);
        margin-top: 0.5rem;
        width: 43rem;
    }
    .header-section {
        display: grid;
        color: #14171D;
        padding: 1rem;
        font-weight: bold;
        .disclaimer-header-text{
            font-size: 20px;
            color: orange;
            padding-left: 0.5rem;
        }
    }

    .modal-content-text{
        padding: 0.5rem 1rem;
        color: #14171D;
        overflow-y: auto;
        font-size: 0.875rem;
    }
    .modal-content{
        padding: 0.5rem 1rem;
        color: #E93255;
        overflow-y: auto;
        font-size: 0.875rem;
    }
    
    .disclaimer-buttons {
        padding: 0.5rem 1rem;
        .decline-button {
            margin-right: 0.313rem;
        color: #00205B;
        }
        .apply-button {
            color: #FFFFFF;
            background-color: #DA1884;
            border-color: #DA1884;
            &:hover &:focus &:focus-visible {
                background-color: #DA1884;
                accent-color: #DA1884;
                color: #FFFFFF;
            }
        }
    }
}
