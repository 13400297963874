$color-wrong: #FF0000;
$color-analysis: #0000FF;
$color-match: #008000;
$color-chip-background: #F8F8FF;
$color-chip-pressed: #ff1493;
$font-fleet-card-text: 0.775rem;
$font-fleet-card-header: 1.3rem;
$font-fleet-card-content: 1.3rem;
$font-fleet-card-value: 0.75rem;
$font-fleet-card-title: 1.3rem;
$font-fleet-container : bold;
$font-weight-label: 650;
$font-weight-value: 500;
$font-weight-title: 600;

@mixin fleet-card-text {
   color: black;
   font-size: $font-fleet-card-text;
}

@mixin dot($color) {
   height: 0.5rem;
   width: 0.5rem;
   border-radius: 50%;
   display: inline-block;
   background-color: $color;
}
.fleet-container {
   font-weight: $font-fleet-container;
   padding-left: 1rem;
   margin-bottom:0.5rem;
}
.fleet-help-icon {
   color:#063B9E;
   cursor: pointer;
}
.nswc-container{
   margin-top: 7.5rem;
   margin-bottom: 16.25rem;
.nswc-text{
   color: #63728A;
   display: flex;
   justify-content: center;
}
.nswc-link{
   display: flex;
   justify-content: center;
}
.nswc-link:visited{
   color: #00205B;
}
}
.infinite-scroll{
   padding-bottom: 1rem;
   overflow: hidden !important;
}
.icon-view {
   .view-buttons {
      margin-right: 1rem;
   }
   .large {
      &:hover &:focus &:focus-visible {
         color: $color-chip-pressed;
         background-color: $color-chip-background;
      }
   }
   .large[aria-pressed='true'] {
      color: $color-chip-pressed;
      background-color: $color-chip-background;
   }
   .small {
      &:hover &:focus &:focus-visible {
         color: $color-chip-pressed;
         background-color: $color-chip-background;
      }
   }
   .small[aria-pressed='true'] {
      color: $color-chip-pressed;
      background-color: $color-chip-background;
   }
}
.spinner-lazyload{
   align-items: center;
   margin-top: 2rem;
   margin-left: 35rem;
}
.card-action {
   .fleet-card-header {
      font-size: $font-fleet-card-header;
      margin-bottom: 0;
      font-weight: $font-weight-title;
      display: flex;
      align-items: center;
      padding-bottom: 0;
      padding-top: 0.5rem;
      padding-left: 1.3rem;
      .fleet-action-icon{
         color:#FFFFFF;
      }
      .fleet-action-icon.warning {
         background-color: $color-wrong;
      }
      .fleet-action-icon.success {
         background-color: $color-match;
      }
      .fleet-action-icon.info {
         background-color: $color-analysis;
      }
   }
   .fleet-card-content {
      padding: 0;
      font-size: $font-fleet-card-content;
      width: 13.75rem;
      .fleet-card-content [class$="content"]{
         padding-bottom: 0;
      }
      .fleet-card-title {
         padding-left: 2.3rem;
         font-size: $font-fleet-card-title;
         margin-bottom:0.2rem;
         font-weight: $font-weight-title;
      }
      .checker-list {
         margin-top: 0.25rem;
      }
      .checker-wrong {
         color: $color-wrong;
      }
   
      .checker-analysed {
         color: $color-analysis;
      }
   
      .checker-matched {
         color: $color-match;
         margin-bottom: 0.5rem;
      }
   
      .fleet-card-label {
         @include fleet-card-text;
         font-weight: $font-weight-label;
      }
   
      .fleet-card-value {
         @include fleet-card-text;
         font-weight: $font-weight-value;
         font-size:$font-fleet-card-value;
      }
   
      .fleet-list {
         padding-bottom: 0;
         font-size: $font-fleet-card-text;
      }
   
      .fleet-list-padding {
         padding: 0.2rem 1rem
      }
   
      div[class$="item-text"] {
         margin-left:0.1rem;
         padding-left:0.1rem;
      }
      .fleet-inline {
         background-color: #f0f4f6;
         padding-left: 0.8rem;
         padding-bottom: 0.4rem;
         .smaller-row {
            margin: 0;
         }
         .fleet-padding {
            padding-left: 0.375rem;
            @include fleet-card-text;
         }
         .fleet-small-view-divider {
            font-weight:200;
            color: lightgray;
         }
      
         .dot-wrong {
            @include dot($color-wrong);
            margin-left:0.6rem;
         }
      
         .dot-analysed {
            @include dot($color-analysis);
         }
      
         .dot-match {
            @include dot($color-match);
         }
      }
   }
}