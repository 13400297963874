@mixin toggle-column-popup{
   padding-top: 2.8rem;
   white-space: nowrap;
   text-align: center;
}
.app-container {
 .top-filter-container{
      padding-left: unset;
      padding-right: unset;
      .filter-panel{
         max-width: 100%;
         height: 6rem
      }
}
.column-tab {
   padding-left: 2rem;
   padding-top: 2.2rem;
}
.datasource-inline {
   padding-top: 0.2rem;
}
.label-datasource {
   font-weight: bold;
}
.text-datasource {
   color: #000000;
   text-align: center;
}
.toggle-column{
   @include toggle-column-popup;
}
.toggle-column-with-popup{
   @include toggle-column-popup;
   pointer-events: none;
}
.concurrent-image {
   margin-left:0.5rem;
   margin-bottom: -0.15rem;
 }
 .concurrent-text{  
   margin-left: 0.5rem;
   font-weight: bold;
   color: #011133;
}
.data-selection-column{
   display: flex;
   flex-direction: row-reverse;
   padding-right: 2.2rem;
}
.dataSelectionPrimary {
   .data-selection-button{
      border-radius: 0.3rem;
      background-color: #002D80;
      color: #FFFFFF;
      margin-top: 2.5rem;
      &:hover &:focus &:focus-visible{
      background-color: #002D80;
      color: #FFFFFF;
      }
}
}
}
