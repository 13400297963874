.detailsPopoverContainer{
    padding-left: 1rem;
    margin-top: 0.128rem;
    .buttonStyle{
        padding: 0.5rem 0.125rem;
        border-color: #002D80;
    }
    .iconStyle{
        margin-left: 0.438rem;
        color: #002D80;
    }
}
.popoverStyle div[class$="arrow"]{
    &:before{
        background-color: #011133;
        }
}
body .popoverStyle{
    background-color: #011133;
    padding: 0.625rem;
    padding-right: 0;
    .closeIcon{
        margin-left: 5.5rem;
        color: #FFF;
        cursor: pointer;
    }
    .headerLabel{
        color: #FFF;
        margin-bottom: 0.188rem;
    }
    .label{
        color: #cecfd3;
        white-space: nowrap;
    }
    .labelData{
        color: #cecfd3;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .acrHeader{
        color: #FFF;
        margin: 0.375rem 0 0.25rem 0;
    }
    .clock-text{
        flex: 0 0 auto;
        width: unset;
        max-width: unset;
    }
}