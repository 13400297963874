@import '../../../variables.scss';

.browserTable{
    padding-top: 1.25rem;
    .toolbar-section{
        background-color: #F8F8FF;
        padding: 1rem;    
        position: absolute;
    }
    .report-status-label{
        display: flex;
        justify-content: center;
        padding-bottom: 1.5rem;
        color: #da1884;
    }
    .report-status-filters-label{
        display: flex;
        justify-content: center;
        padding-bottom: 0.5rem;
        color: #da1884;
    }
    .report-toggle-div {
        padding-left: 13px;
        div[class^="ds-toggle"] {
            top: 0.8vh;
        }
    }
    .report-toggle{
        color: black;
        margin-top: 0.6rem;
        font-weight: bold;
    }
    .cmi-pagination{
        padding-bottom: 1.2rem;
        div[class$="legend"] {
            font-weight: bold;
            order: 2
        }
        div[class$="rows"] {
            order: 1;
        }
        nav[class$="nav"] {
            order: 3
        }
    }
    .sb-browser-data-table{
        height : calc(100vh - 35vh);
        div[class$="body"] {
            min-height: 35vh;
        }
    }
    //added custom scroll bar
    ::-webkit-scrollbar {
        width: 0.2rem;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $font_white;  
        border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        height: 204px;
        -webkit-box-shadow: inset 0 0 6px  $scrollbar-color; 
        background-color: $scrollbar-color ;
    }
    .grayout-background {
        opacity: 0.5;
        pointer-events: none;
    }
    .gray-out-toolbar{
        opacity: 0.5;
        pointer-events: none;
    }
    .visible-background {
        opacity: 1;
    }
}
.checker-details-container .cmi-pagination{
    nav[class$="nav"] {
        margin-right: 0rem;
    }
}
.checker-details-container .filterSelection .data-filter-button{
  margin-right: 0rem !important;
  margin-top: 0.2rem !important;
}

