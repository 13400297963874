.app-container .expCardContainer {
    border-style: solid;
    border-color: #001b4d;
    padding: 1rem;
    .card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .parentCardContainer {
        border-style: solid;
        border-color: #9ea9ba6b;
        padding: 1rem;
        .parentContainer {
            padding: 0.5rem;
            .expression {
                .expressionValue {
                    font-weight: bold;
                }
                .expressionDescription {
                    padding-left: 1.5rem;
                }
            }
        }
    }
    .childOperator {
        padding: 0.5rem;
        text-align: left;
        .chip-error {
            background-color: #DA1884;
            color: #ffff; 
        }
        .chip-info {
            background-color: #002D80;
            color: #ffff; 
        }
        .chip-warning {
            background-color: #E93255;
            color: #ffff; 
        }
    }
    .parentOperator {
        padding: 0.5rem 0rem 0.5rem 0.5rem;
        text-align: left;
        .chip-error {
            background-color: #DA1884;
            color: #ffff; 
        }
        .chip-info {
            background-color: #002D80;
            color: #ffff; 
        }
        .chip-warning {
            background-color: #E93255;
            color: #ffff; 
        }
    }
    .missing-sb {
        padding-left: 2rem;
        font-weight: bold;
    }
}
