.chip-container .chip-right {
    display: contents;
    background-color: inherit;
    [class$="-label"] {
        display: none;
    }
    [class$="icon"] {
        .check-icon {
            color: #706b6b;
        }
        .cross-icon {
            color: #c5bebe;
        }
        padding: 0;
        svg {
            width: 1.4em;
            height: 1.7em;
        }
    }
}
.chip-container .source-text{
    color: #c5bebe;
    margin: 0;
    padding: 0.125rem 0;
}
.chip-container {
    [class$="warning"]{
        background-color: #ff0000d6;
    }
}