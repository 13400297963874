.app-container .concurrentCard {
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 20%);
    transition: 0.3s;
    margin-top: 0.3rem;
    background: white;
    width: auto;
    text-align: left;
    .rowPadding {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .columnMargin {
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
    }
    .concurrentParagraph {
        padding-top: 0.5rem;
        padding-left: 1rem;
        font-weight: bold;
    }
    .concurrentLabel {
        font-weight: bold;
        color: #6E6E6E;
        padding-right:0.3rem;
    }
    .concurrentText {
        color: #000000;
        border-color: #808080;
        background-color: #EEEEEE;
        text-align: center;
        padding: 0 0.2rem 0 0.2rem;
    }
}
