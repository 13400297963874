@import '../../variables.scss';

.cmiAppHeader{
    .user-button{
        box-shadow: none;
        background-color: initial;
        color: $font_white;
        width: fit-content;
        border: none;
        position:sticky;
        &:hover, &:focus, &:focus-visible{
            background-color: inherit;
            border: none;
            position:sticky;
            box-shadow: none;
        }
        svg { 
            width: 2rem;
            height: 2rem;
            pointer-events: none;
            }
}
}

.usermenu-popup{
    width: auto;
    padding-left: 0.75rem;
    .logout-button {
        color: $font_black;
        font-size: 0.875rem;
        border: none;
    }
    .profile-icon{
        color: #011133;
    }
    .profile-row{
        width: 5.313rem;
        margin-right: 3.125rem;
        padding: 0.25rem;
    }
}