@mixin checker-data-table($height) {
  height: calc(100vh - $height);
    div[class$="body"] {
      min-height: 30vh;
    }
    div[role="rowgroup"] {
      height: inherit !important;
      div[role="row"] {
        height: fit-content;
        div[role="cell"] {
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          border: 0.0625rem solid #80808047;
          table {
            border-collapse: collapse;
            height: 100%;
            width: 100%;
          }
          tr {
            border-bottom: 0.0625rem solid #80808047;
          }
          tr:last-child {
            border-bottom: 0;
          }
          td {
            overflow-wrap: anywhere;
            vertical-align: middle;
          }
          .cell-values {
            display: inherit;
            align-items: center;
            padding: 0.25rem 0.875rem 0.25rem 0.75rem;
            font-size: 0.75rem;
            font-weight: 500;
            height: 100%;
            width: 100%;
            .partCheck-NA-style {
              display: contents;
              width: 100%;
            }
            .chip-right {
              width: 100%;
            }
            .reason-attachment-button {
              padding: 0;
            }
          }
        }
        div[role="cell"]:nth-last-child(5) {
          .cell-values {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        div[role="cell"]:nth-last-child(1) {
            border-left: 0;
        }
        div[role="cell"]:last-child {
          .cell-values {
            justify-content: flex-start;
            text-overflow: ellipsis;
            overflow: hidden;
            text-wrap: nowrap;
          }
        }
        div[role="cell"]:nth-last-child(7),
        div[role="cell"]:nth-last-child(4),
        div[role="cell"]:nth-last-child(2) {
          .cell-values {
            display: block;
          }
        }
      }
    }
    div[role="columnheader"] {
      border-right: 0.0625rem solid #80808047;
      padding: 0 0 0 0.875rem;
      color: #011133;
      font-weight: bold;
      div[role="button"] {
        font-weight: 700;
      }
    }
    ::-webkit-scrollbar { 
      width: 0.063rem;
      height: 0.5rem;
    }
    .status-editCheckbox {
      padding-left: 0.5rem;
      padding-top: 0.25rem;
    }
    .edited-chip {
      color: grey;
      margin-top: -0.4rem;
      margin-left: -0.2rem
    }
  }  

.checker-details-container {
  position: absolute;
  z-index: -4;
  background-color: #F8F8FF;
    .details-icon svg{
    width: 1.3rem;
    height: 1.3rem;
  }
  .data-warning-text{
    text-align: center;
    color: #F23346;
    margin-top: 0.25rem;
  }
  .checker-data-table {
    @include checker-data-table(15rem)
   }
  .checker-data-table-fin-edit {
    @include checker-data-table(18.125rem)
  }     
  .custom-chips {
    height: 1rem;
    color: #ffffff;
    span {
      padding: 0 0.55rem 0 0.55rem;
      font-size: 0.675rem;
    }
  }
  .tba {
    background-color: #2a78fb;
  }
  .mismatch {
    background-color: #ff0000d6;
  }
  .match {
    background-color: #008000;
  }
}
.elipseColumn .cell-values{
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
  white-space: nowrap;
}
body .checker-no-data{
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: -4;
  .checker-spinner{
    margin-top: 9.375rem;
  }
}
