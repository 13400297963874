.tooltip-container{
  .fin-info{
    margin-top: 0.25rem;
  }
  .header-info {
    padding-left: 0.3rem;
  }
  .source-info{
            margin: 0.35rem 0 0 1.5rem;
        }
        .tooltip-col{
          flex: 0 0 auto;
          width: 0;
          margin-left: 0.1875rem;
        }
}
body .tooltip-info{
    width: 9.25rem;
    padding: 0.188rem 1.125rem;
    .chip-col{
        padding: 0.313rem 0.063rem;
    }
    .chip-text{
        padding: 0.375rem 0.313rem 0 0.313rem;
        color: #cecfd3;
      }
    
}
body  .tooltip-source-info{
  width: 15.313rem;
  padding: 0.25rem 0.958rem;
  .chip-right
      {
        background-color: unset;
      }
      .chip-col{
        padding: 0.438rem 0 0.25rem 0.75rem;
            }
      .chip-text{
        padding: 0.25rem 0.25rem 0.125rem 0rem;
        margin-left: -1.68rem;
        color: #cecfd3;
      }
}
.tooltip-info div[class$="arrow"]{
    &:before{
       margin-left: 0.5rem;
        }
}