div[id="help-modal-popup"] {
    position: relative;
    z-index: -1;
}
.app-container .popup-help-container {
    div[class^="ds-backdrop"] {
        margin-top: 10rem;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        position: fixed;
        background-color: rgba(1,17,51,0.6);
        -webkit-tap-highlight-color: transparent;
    }
    .help-container {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, 0%);
        padding: 1rem 1rem 0.3rem 1rem;
        width: 44rem;
        background-color: white;
        border-radius: 0.3rem;
        border: 1px solid rgba(1,17,51,0.6);
    }
    .help-content{
        padding-top: 4%;
    }
    .close-button{
        color: #FFFFFF;
        background-color: #063B9E;
        border-color: #063B9E;
        border-radius: 3px;
    }
    .header-label
    {
        padding-bottom: 0.2rem;
        font-weight: bold;
        text-align: center;
        font-size: 1rem;
    }
    .mandatory-label{
        text-align: left;
    }
    .modal-close-icon
    {
        margin-left: 18.3rem;
        color: #063B9E;
        cursor: pointer;
    }
    .assteriskRed{
        color: #d0312d;
        display: inline-flex;
    }
    .mandatoryText{
        display: inline-flex;
        padding-left: 0.4rem;    
    }
    .dataFilterHelplist{
        font-size: 1rem;
        ul{
            padding-left: 1rem;
            li {
                .helpCheckbox {
                    width:1rem;
                    height:1rem;
                }
            }
        }
    }
}