.AppBody .notification-container{
    padding:0;
    .side-nav{
    overflow-y: unset;
    .notification-selection [class$="link"]{
        background-color: #E5ECF7;
        ;
    }
}
.notification-text-content{
    font-size: 0.875rem;
    font-weight: 700;
    color: #00205B;
}
.text-content{
    font-weight: 600;
    color: #14171D;
}
.breadcrumb-container{
    width: 90%;
    background-color: #eceff2;
    .breadcrumb-container-item{
    padding: 0.313rem 1.25rem;
    }
    .home-page:visited{
        color:  #00205B;
    }
    .main-container{
        padding: 0.25rem 2rem;
        .alert-container{
            margin: 0.938rem 0 0 0;
            padding: 0.438rem 0 6.25rem 0.625rem;
            background-color: #ffffff;
        }
        .text-notify{
            padding: 0.313rem 0 0.938rem 0;
            color: #63728A;
        }
        .email-checkbox [class$="label"]{
            font-weight: 700;
            color: #14171D;
        }
        }
}

}
.email-popup-container {
    .modal-container {
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -10%);
        padding: 0rem 0.1rem 0.1rem 0.1rem;
        background-color: white;
        border-radius: 0.3rem;
        border: 1px solid rgba(1,17,51,0.6);
        margin-top: 0.5rem;        
    }
    .email-header-section {
        padding: 0.938rem 0 0.125rem 0.438rem;
        font-weight: bold;
    }
    
    .email-close{
        cursor: pointer;
        margin-left: 4.375rem;
    }
    .modal-container-outer {
        .modal-container-card {
            margin: 0.5rem;
            padding-bottom: 1rem;
        }
    }
    .modal-content{
        margin-top: 0.5rem;
        overflow-y: auto;
        font-size: 0.875rem;
    }
    .email-buttons {
        padding-bottom: 2rem;
    }
}
