.app-container .toast-cls {
    color: #ffff;
    position: relative;
    position: -webkit-sticky;
    top:0;
    background-color: rgb(202, 37, 45);
    height: 0.5em;
    border: 1px solid #Ffff;

    .toast-customisation {
        width: 100%;
    }
}

.app-container .toastwarning{
    @extend .toast-cls;
    background-color: rgb(235, 134, 0);
    color: #ffff
}

.app-container .toastsuccess{
    @extend .toast-cls;
    background-color: rgb(17, 128, 93);
    color: #ffff
}