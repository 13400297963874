@mixin filter-menu-default($heightCombobox, $widthArrow, $heightArrow, $marginRightArrow, $marginTopArrow) {
        height: $heightCombobox;
        width: -webkit-fill-available;
        font-weight: normal;
        display: flex;
        position: relative;
        flex-wrap: wrap;
        box-shadow: 0 -1px 0 0 #a2acbd inset;
        transition: 0.1s ease-in-out;
        border: none;
        border-radius: 3px 3px 0 0;
        padding-right: calc(4rem + 0.6rem);
        background-color: #f7f9fa;
        transition-property: box-shadow, border;
        justify-content: left;
        &:disabled {
            opacity: 0.3
        }
        .arrow {
            position: absolute;
            top: calc(50% - 0.7rem);
            right: calc(5% - 1rem);
            svg {
                width: $widthArrow;
                height: $heightArrow;
                margin-right: $marginRightArrow;
                margin-top: $marginTopArrow
            }

        }
}

.hybrid-combo-button {
    .filter-menu-button {
        @include filter-menu-default(2.5rem, 1.2rem, 1.2rem, 0.5rem, 0rem);
    }

    .filter-menu-button-column-level {
        @include filter-menu-default(2.2rem, 1rem, 1rem, 0.1rem, 0.2rem);
    }

    button[type="button"] {
        padding: calc(0.5rem - 1px) 0.7rem;
    }
}

.typaahead {
    input {
        padding-left: 1.6rem;
    }
}

.menu-options {
    overflow-y: auto;
    overflow-x: hidden;
    height: 10rem;

    .first-menu {
        margin-left: 1rem;

        .multiselect-checkbox {
            padding-bottom: 0.3rem;
            padding-left: 0.3rem;
        }

        .multiselect-checkbox input[type='checkbox']:checked+label::before {
            border-color: #da1884;
            background-color: #FFFFFF;
        }

        .multiselect-checkbox input[type='checkbox']:checked+label::after {
            background-color: #da1884;
        }
    }

    .select-all {
        margin: 1rem;
        margin-bottom: 0.3rem;

        .checker-multiselect-filter-reset {
            align-items: normal;
            cursor: pointer;

            .checker-multiselect-filter-resetlabel {
                color: #011133;
                padding-left: 0.2rem;
            }

            .checker-multiselect-filter-reset-icon {
                padding-left: 0.2rem;
                padding-top: 0.04rem;
            }
        }
    }

    .select-all input[type='checkbox']:checked+label::before {
        border-color: #da1884;
        background-color: #FFFFFF;
    }

    .select-all input[type='checkbox']:checked+label::after {
        background-color: #da1884;
    }

    .maxSelection-note {
        width: max-content;
        font-weight: 600;
    }
}

.checkbox-action-buttons {
    margin-bottom: 0.5rem;

    .multiselect-apply-button {
        margin-top: 0.3rem;
        color: #FFFFFF;
        background-color: #063B9E;
        border-color: #063B9E;
        border-radius: 3px;
        &:hover &:focus &:focus-visible {
            background-color: #002D80;
            color: #063B9E;
        }
    }

    .multiselect-cancel-button {
        margin-top: 0.3rem;
        margin-left: 0.5rem;
        margin-right: 1.5rem;
        color: #063B9E;
        background-color: #FFFFFF;
        border-color: #063B9E;
    }

    .maxSelection-selected-label {
        color: #DA1884;
        width: -webkit-fill-available;
        position: relative;
        left: 1rem;
        top: 1rem;
    }
}

::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.375rem white;
    border-radius: 0.25rem;
}

::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    height: 3.5rem;
    -webkit-box-shadow: inset 0 0 0.375rem #9DB8BE;
    background-color: #9DB8BE;
}

