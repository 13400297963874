.app-container {
    .expressionContainerStyle {
        .collapseDiv {
            padding: 1rem;  
            cursor: pointer;
        }
        .Collapsible {
            box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 20%);
            margin-left: -1.9rem;
            margin-right: -1.9rem;
            .collapsibleSection {
                padding: 1rem;           
            }
        }
    }
}