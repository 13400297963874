.app-container .expressionDiv {
    text-align: left;
    padding-left: 1rem;
    .expressionText {
        display: inline-block;
        text-align: left;
        margin-right:20rem;
        .missing-sb-text {
            font-weight: bold;
            padding-left: 1rem;
        }
    }
    .expressionIcon {
        float: right;
        border: none;
        margin-top:-2rem;
    }

    .collapse-Icon {
        justify-content: flex-end;
        padding-right: 2rem;
    }
}