.tab-container{
    .tab-select-container{
      .selected-tab{
            &:before{
            border: none;
            }
            &:after{
                border: 2px solid #ffffff;
                font-weight: bold;
                }
        }
        .selected-tab[class$="large"]{
            font-weight: 100;
        }
    }
}
