.App {
    text-align: center;
  }
.app-container
{
    .AppBody
  {
      padding: 0;
      overflow-x: clip;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  .bottomToast
  {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position:absolute;
      left: 30%;
      z-index: 1200;
      bottom: 1px;
      background-color: #e4002b;
  }
}
