@import '../../variables.scss';

.custom-header .upload-file-Popover-Container{
    padding: 0;
    .file-button{
        border: none;
    }
}
.upload-file-popover{
    width: 12rem;
    .upload-file-button{
        color: $font_black;
        font-size: 0.8rem;
        border: none;
        font-family: inherit;
        font-weight: 400;
        &:hover, &:focus, &:focus-visible{
            background-color: inherit;
            border: none;
            position:sticky;
            box-shadow: none;
        }
        .icon-color{
            color: #011133;
        }
    }
}
