@mixin fleet-checker-filters {
   padding-right: 2rem;
   .spinner-left {
      left: -2rem;
      position: absolute;
   }
   .checker-filter-label {
      color: #505d74;
      width: 20vw;
      padding-bottom: 0.4rem;
      padding-top: 0.5rem;
      font-weight: bold;
   }
}

.checker-top-tab-container .checker-filters {
  @include fleet-checker-filters;
}

.checker-top-tab-container .checker-filters-operators{
   @include fleet-checker-filters;
   max-width: 10vw;
}

.checker-top-tab-container .checker-filters-reset {
   padding-top: 2.5rem;
   cursor: pointer;
   .checker-filter-resetlabel {
      color: #011133;
      font-weight: bold;
   }
   .checker-filter-reset-icon {
      padding-top: 0.1rem
   }
}
