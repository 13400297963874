.custom-download-drawer {
  overflow: hidden;
  min-width: 30.7rem;
}

.download-drawer-container {
  position: relative;
  top: 56px;

  .download-text {
    font-weight: bold;
    padding: 0.625rem;
    margin-left: 0.938rem;
  }

  .close-drawer {
    cursor: pointer;
    padding: 0rem;
    margin-top: 0.5rem;
    margin-left: 3rem;
    font-size: 1.3rem;
  }

  .download-drawer-header {
    padding: 0.625rem;
    background-color: #1a1f341a;
  }

  .close-button-align {
    padding-right: 1.875rem;
  }

  .download-align {
    display: flex;
    justify-content: flex-end;
  }
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.375rem white;
    border-radius: 0.25rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    height: 3.5rem;
    -webkit-box-shadow: inset 0 0 0.375rem #9DB8BE;
    background-color: #9DB8BE;
  }
}