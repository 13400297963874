@import '../../variables.scss';

.custom-header .user-Help-Popover-Container{
    padding: 0;
    .menu-button{
        border: none;
    }
}
.user-menu-popover{
    width: 12rem;
    .help-centre {
        color: $font_black;
        font-size: 0.8rem;
        border: none;
        font-family: inherit;
        font-weight: 400;
        &:hover, &:focus, &:focus-visible{
            background-color: inherit;
            border: none;
            position:sticky;
            box-shadow: none;
        }
    }
    .app-version {
        color: #A2ACBD;
        font-size: 0.9rem;
        padding-left: 0.9rem;
        padding-bottom: 0.438rem;
    }
}
