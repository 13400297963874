div[id="browser-modal-popup"] {
    position: absolute;
    z-index: -1;
}
.modal-popup-container {
    .modal-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -30%);
        padding: 1rem 1rem 0.3rem 1rem;
        width: 50rem;
        background-color: white;
        display: flex;
        border-radius: 0.3rem;
        border: 1px solid rgba(1,17,51,0.6);
    }
    .filter-row, .warning-row {
        margin-bottom:0.5rem;
    }
    .warning-text{
        color:black;
        font-weight: bold;
    }
    .inline-button{
        margin-top: 1rem;
    }
    .reset-button{
        color:#063B9E;
        background-color: #FFFFFF;
        border-color: #063B9E;
    }
    .dataSelectionPrimary {
        .validate-button{
            color:#FFFFFF;
            background-color: #063B9E;
            border-color: #063B9E;
            border-radius: 3px;
            &:hover &:focus &:focus-visible{
                background-color: #002D80;
                color: #FFFFFF;
                }
        }
    }
    .validate-button-disabled{
        color:#FFFFFF;
        background-color: #A5C0F2;
        border-color: #A5C0F2;
        border-radius: 3px;
    }
    .form-selector {
        height: 10px;
    }
    .popup-icon {
        color:#063B9E;
    }
    .help-icon {
        color:#063B9E;
        cursor: pointer;
    }
    .required-fields{
        color:#d0312d;
        padding-left: 0.2rem;
    }
    .filter-label
    {
        color: #505d74;
        padding-bottom: 0.2rem;
        padding-top: 0.5rem;
    }
    .header-label
    {
        padding-bottom: 0.2rem;
        font-weight: bold;
    }
    .modal-close-icon
    {
        margin-left: 18.3rem;
        color: #063B9E;
        cursor: pointer;
    }
    .filter-row .hybrid-combo-button .filter-menu-button{
        width: 30.5rem;
        padding-left: 0.6rem;
        .arrow {
            position: absolute;
            top: calc(50% - 0.7rem);
            right: calc(5% - 1.2rem);
        }
    }
}
body .hybrid-checker-details-msn-popover{
    width: 30.5rem;
    .checkbox-action-buttons{
     display: flex;
     justify-content: flex-end;
     margin-right: 0.25rem;
    }
    .clear-all{
        display: flex;
        padding-right: 4rem;
        flex-direction: row-reverse;
    }
}
// Since this is dynamically generated we are refering the div for popover from the body
body div[class^="ds-popover"]
        ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }
        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px white;  
            border-radius: 4px;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            height: 3.5rem;
            -webkit-box-shadow: inset 0 0 6px  #9DB8BE; 
            background-color: #9DB8BE ;
        }