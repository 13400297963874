.browserTable .sb-browser-data-table .redirect-airnavx{
    display: contents;
    font-size: 0.875rem;
}
.concurr-msn-sb {
    color: #123FAE;
    text-decoration: underline;
    .elipseColumn .cell-values{
        cursor: pointer;
    }
}