.report-status-container {
  .report-status-div{
    margin: 1.25rem 0;
  }
  .icon-button-reload{
    background: none;
  }
  .relaod-text{
    margin-left: 0.313rem;
    padding-right: 0.313rem;
  }
  .report-card-content {
    padding: 0.063rem;

    .no-data-label {
      position: absolute;
      text-align: center;
      width: 100%;
      top: 40%;
      color: #232020;
      font-weight: 600;
    }
  }
  .legend-container{
    display: flex;
    flex-wrap: wrap;
    margin: 0.938rem 0 0 0;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    background-color: #FAFAFA;
    min-height: 3.75rem;
    .legend-div{
      padding: 0px 0.6rem;
      .s1{
        height: 0.875rem;
        width: 0.875rem;
        background-color: #18A272;
      }
      .s2{
        background-color:  #A3DA22;
        height: 0.875rem;
        width: 0.875rem;
      }
      .s3{
        background-color:  #92001C;
        height: 0.875rem;
        width: 0.875rem;
      }      .s4{
        height: 0.875rem;
        width: 0.875rem;
        background-color: #F23346;
      }
      .s5{
        background-color: #C8EAFF ;
        height: 0.875rem;
        width: 0.875rem;
      }
      .s6{
        background-color:  #219AE1;
        height: 0.875rem;
        width: 0.875rem;
      }
      .s7{
        background-color:  #CFDDF8;
        height: 0.875rem;
        width: 0.875rem;
      }
      .s8{
        background-color:  #255FCC;
        height: 0.875rem;
        width: 0.875rem;
      }
      .legend-text{
        font-weight: 600;
      }
      .chart-openInNew {
            margin: 0px 1px 0 0;
            color: #123fae;
            .new-tab-filter-link{
              padding: 0;
            }
            .new-tab-filter-link:visited {
              color: inherit;
            }
          }
    }
  }
  .pieChart-data{
    margin: 0 auto;
    height: 10.938rem;
      width: 100%;
  }
  .chart-title-checker {
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 0;
    padding: 0.625rem 0.063rem 0rem 0.938rem;

    .msn-contribution-label {
      color: #ff0000;
      position: relative;
      bottom: 0.688rem;
      max-height: 0;
      font-weight: 400;
      font-size: small;
    }
  }
  .checker-report-spinner {
    position: absolute;
    text-align: center;
    left: 45%;
    top: 39%;
  }
  .report-status-row {
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 1.7vh;

    .report-status-column {
      flex-grow: 1;
      .report-status-card {
        box-shadow: 0 0 0.25rem 0.063rem #88888826;
        min-height: 18rem;
        min-width: 96%;
      }
    }
  }
}