
div[id="download-modal-popup"] {
    position: absolute;
    z-index: 0;
}
#download-modal-popup .download-container{
    .close-button{
        color: #FFFFFF;
        background-color: #063B9E;
        border-color: #063B9E;
        border-radius: 3px;
    }
    .header-label
    {
        padding-top: 0;
        padding-bottom: 0.2rem;
        font-weight: 700;
        font-size: 1rem;
    }
    .download-header{
        font-size: 1rem;
    }
    .download-label{
        padding-bottom: 1.2rem;
        font-weight: 700;
        font-size: 1rem;
        padding-top: 0;
    }
    .download-content{
        padding-top: 0.25rem;
        padding-bottom: 0.24rem;
    }
    .collapse-Icon{
        margin-top: 3%;
        margin-left: 13px;
    }
    .errorIcon{
        color: rgb(189,97,100);
        border: none;
    }
}